.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.input-field {
  font-size: 2rem;
  letter-spacing: 0.5rem;
  text-align: center;
  max-width: 300px;
}

.container.success .input-field {
  animation: success-glitch 0.5s ease-in-out;
}

.container.error .input-field {
  animation: error-glitch 0.5s ease-in-out;
}

@keyframes success-glitch {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #098f23;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes error-glitch {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: red;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.pulse {
  animation: pulse 3s infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in > .MuiGrid-item {
  animation-fill-mode: forwards;
}

/* LoginWithOTP.css */
.container {
  transition: all 3s ease-in-out;
  font-family: "Roboto", sans-serif;
}

.container.success .input-field {
  border-color: green;
}

.container.error .input-field {
  border-color: red;
}

.unlock-animation {
  animation: unlock 3s forwards;
}

@keyframes unlock {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(2);
  }
}
