/* Styles for the URL Expired component */

.url-expired-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: #ffcccc; /* Light red background for visibility */
  color: #ff0000; /* Red text for emphasis */
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.url-expired-message {
  font-family: "Arial Black", Gadget, sans-serif;
  font-size: 8vw; /* Responsive font size */
  font-weight: bold;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .url-expired-message {
    font-size: 12vw; /* Adjust font size for smaller screens */
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 5s ease-in-out;
}
