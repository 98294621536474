.world-clock-container {
  position: fixed;
  top: 100px;
  right: 20px;
  width: 200px;
  height: 200px;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 50%;
  overflow: hidden;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.clock-face {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clock-center {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #000;
  border-radius: 50%;
  z-index: 10;
}

.hour-hand,
.minute-hand,
.second-hand {
  position: absolute;
  background-color: #000;
  transform-origin: 50% 100%;
}

.hour-hand {
  width: 4px;
  height: 40px;
  top: 60px;
  left: calc(50% - 2px);
  z-index: 100000;
  background-color: rgb(26, 24, 24);
}

.minute-hand {
  width: 2px;
  height: 60px;
  top: 40px;
  left: calc(50% - 1px);
  z-index: 100000;
  background-color: rgb(26, 24, 24);
}

.second-hand {
  width: 1px;
  height: 70px;
  top: 30px;
  left: calc(50% - 0.5px);
  z-index: 100000;
  background-color: rgb(26, 24, 24);
}

.world-map {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("https://cdn.pixabay.com/photo/2018/05/18/16/41/globe-3411506_1280.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  z-index: 5;
  opacity: 0.8;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.pulse {
  animation: pulse 3s infinite;
}
