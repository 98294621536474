@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 3s ease-in-out;
}

/* Add this CSS to style your components */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.watermark {
  position: relative;
}

.watermark::before {
  /* content: "Confidential"; */
  /* position: fixed; */
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  font-size: 48px;
  color: rgba(255, 0, 0, 0.3);
  /* z-index: 9999; */
  pointer-events: none;
  user-select: none;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  z-index: 1000;
}

.icons {
  display: flex;
}

main {
  margin-top: 70px; /* Adjust this value to match the height of the header */
  margin-bottom: 70px;
  /* padding: 20px; */
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.product-card {
  border: 1px solid #ccc;
  padding: 16px;
  width: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-card img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.cart {
  margin-top: 88px;
}

.cart-item {
  border-bottom: 1px solid #ccc;
  padding: 8px 0;
}

.quantity-control {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.quantity-control button {
  padding: 5px 10px;
  font-size: 16px;
  margin: 0 5px;
}

input[type="text"] {
  padding: 8px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}
