.custom-app-bar {
  position: fixed;
  bottom: 0; /* Fix to bottom */
  left: 0;
  right: 0;
  z-index: 1301; /* Keep it above other elements */
  background: linear-gradient(180deg, #f0f9ff 20%, #ffffff 80%);
}

.main-content {
  padding-bottom: 60px; /* Ensure content does not overlap AppBar */
}

.user-name {
  flex-grow: 1;
  text-align: left;
}

.icon-group {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Icons align to the left */
  gap: 16px; /* Space between icons */
  width: auto;
}

/* Mobile styles */
@media (max-width: 600px) {
  .user-name {
    font-size: 1rem;
  }

  .icon-group {
    flex-grow: 1;
    justify-content: space-between;
    gap: 24px; /* Larger space between icons on mobile */
  }

  .MuiSvgIcon-root {
    font-size: 1.2rem; /* Resize icons for smaller screens */
  }

  .MuiBadge-badge {
    font-size: 0.75rem;
    min-width: 16px;
    height: 16px;
  }
}

/* Animation for cart badge */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5); /* Subtle pulse effect */
  }
  100% {
    transform: scale(1);
  }
}

.animate {
  animation: pulse 0.5s ease-in-out; /* Shorter, smoother pulse */
}
