/* Keyframe animation for each letter */
@keyframes typingAnimation {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animation applied to each letter */
/* .letter {
  display: inline-block;
  animation: typingAnimation 0.5s ease-in-out forwards;
  animation-delay: var(--delay);
} */
